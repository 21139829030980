import React from 'react';
import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { NavLink } from 'react-router-dom';
import { REACT_APP_ATTACHMENT_URL } from '../../config/envConfig';
import { useGetHeaderListDataQuery } from '../../store/services/layouts';
import { formatNumToCurrency, handleImageLoadingError, RenderIf } from '../../utils';
import defaultLogo from '../../assets/default-section-images/defaultLogo.webp';

function Header() {
  // redux state
  const { data = [] } = useGetHeaderListDataQuery();
  console.log('logo heading data', data);

  return (
    <header className="hidden items-center px-4 py-2 sm:flex lg:px-10">
      <NavLink to="/">
        <img
          src={`${REACT_APP_ATTACHMENT_URL}${data?.logo?.value}`}
          alt="brand logo"
          className="w-16"
          onError={(e) => handleImageLoadingError(e, defaultLogo)}
        />
      </NavLink>
      <ul className="ms-auto flex gap-x-5">
        <RenderIf render={data?.live_rate_diamond.value === '1'}>
          <Menu>
            <MenuHandler>
              <li>Diamond - {formatNumToCurrency(10000)}</li>
            </MenuHandler>
            <MenuList>
              <MenuItem>24KT - {formatNumToCurrency(1000)}</MenuItem>
              <MenuItem>22KT - {formatNumToCurrency(5000)}</MenuItem>
            </MenuList>
          </Menu>
        </RenderIf>
        <RenderIf render={data?.live_rate_gold.value === '1'}>
          <Menu>
            <MenuHandler>
              <li>Gold - {formatNumToCurrency(2000)}</li>
            </MenuHandler>
            <MenuList>
              <MenuItem>24KT - {formatNumToCurrency(1000)}</MenuItem>
              <MenuItem>22KT - {formatNumToCurrency(5000)}</MenuItem>
            </MenuList>
          </Menu>
        </RenderIf>
        <RenderIf render={data?.live_rate_silver?.value === '1'}>
          <Menu>
            <MenuHandler>
              <li>Silver - {formatNumToCurrency(2000)}</li>
            </MenuHandler>
            <MenuList>
              <MenuItem>24KT - {formatNumToCurrency(1000)}</MenuItem>
              <MenuItem>22KT - {formatNumToCurrency(5000)}</MenuItem>
            </MenuList>
          </Menu>
        </RenderIf>
        <RenderIf render={data?.find_store?.value === '1'}>
          <li>
            <NavLink to="#">Find a store</NavLink>
          </li>
        </RenderIf>
        <RenderIf render={data?.support?.value === '1'}>
          <li>
            <NavLink to="#">Support</NavLink>
          </li>
        </RenderIf>
      </ul>
    </header>
  );
}

export default Header;
