/* eslint-disable */

import React from 'react';
import Slider from 'react-slick';
import { handleImageLoadingError, RenderIf } from '../../../utils';
import NoSectionMatched from '../others/NoSectionMatched';
import heroSectionDefualt from '../../../assets/default-section-images/herosectiondeafult.jpg';
import useSliderSetting from '../../../hooks/useSliderSetting';

function HeroSlider({ bgImage, description, contentData, controls, limit, title, data }) {
  const settings = useSliderSetting(controls?.SimpleSlider);

  return (
    <>
      <RenderIf
        render={data?.content_data?.length > 0}
        elseRender={<NoSectionMatched title={'Hero Slider Section'} />}
      >
        <Slider {...settings}>
          {contentData.map((item) => {
            return (
              <div key={item?.id} className="mt-0">
                <img
                  src={`${item?.image_url}/public` || heroSectionDefualt}
                  onError={(e) => handleImageLoadingError(e, heroSectionDefualt)}
                  alt="Slide 1"
                  className="h-[600px] w-full object-fill"
                />
              </div>
            );
          })}
        </Slider>
      </RenderIf>
    </>
  );
}

export default HeroSlider;
