import React, { useRef } from 'react';
// import { IconButton } from '@material-tailwind/react';
// import { GoArrowLeft, GoArrowRight } from 'react-icons/go';
import Slider from 'react-slick';

import Heading from '../../customUi/heading/Heading';
import { RenderIf } from '../../../utils';
import NoSectionMatched from '../others/NoSectionMatched';
// import useSliderSetting from '../../../hooks/useSliderSetting';
// import { LeftRightArrow } from '../../customUi/arrow/LeftRightArrow';

function ConsecutiveCardSlider({ data, contentData }) {
  const { title, description } = data;
  // const image = contentData?.image_url;
  // console.log('contentData consecutive', contentData);

  // // initial state
  // const settings = useSliderSetting(data?.parsedControls?.SimpleSlider);

  const sliderRef = useRef(null);

  const settings = {
    slidesToShow: 3.5,
    // ...useSliderSetting(data?.parsedControls?.SimpleSlider),

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // // local state
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3.5,
  //   slidesToScroll: 4,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <RenderIf
        render={data?.content_data?.length > 0}
        elseRender={<NoSectionMatched title={title || 'Consecutive Card Slider'} />}
      >
        <div className="mx-2 p-0 text-black lg:mx-[120px]">
          <Heading title={title} description={description} sliderRef={sliderRef} arrow={true} />
        </div>

        <div className="snap-x snap-mandatory">
          <Slider ref={sliderRef} {...settings}>
            {contentData.map((item) => (
              <div key={item.id}>
                <div className="snap-center">
                  <img
                    src={`${item?.image_url}/public`}
                    alt="Discover your shine"
                    className="w-full"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </RenderIf>
    </>
  );
}

export default ConsecutiveCardSlider;
