import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

import { NavLink } from 'react-router-dom';
import { useGetNavMenuListQuery } from '../../store/services/layouts';
import { getNavigationPath } from '../../utils';

// DesktopNav Component
function DesktopNav() {
  // redux state
  const { data = [] } = useGetNavMenuListQuery();

  const slicedData = data.length > 7 ? data.slice(0, 7) : data;

  return (
    //font-primary z-10 hidden gap-x-10 lg:flex
    <ul className="z-10 hidden gap-x-10 lg:flex">
      <MenuItems menuItems={slicedData} />
    </ul>
  );
}

const MenuItems = ({ menuItems }) => {
  return menuItems?.map((item, index) => (
    <li key={item.id || item.name} className="group relative py-2">
      <NavLink
        to={getNavigationPath(item)}
        className="group relative flex items-center text-gray-800 transition-all duration-500 hover:text-primary"
      >
        <span className="transform transition-transform duration-300 group-hover:scale-95">
          {item.name}
        </span>

        {item.sub_menu?.length > 0 && (
          <FaChevronDown className="ml-1 inline-block transform transition-transform duration-500 group-hover:rotate-180" />
        )}

        {/* Underline Effect */}
        <span className="absolute -bottom-1 left-0 h-[3px] w-full scale-x-0 bg-primary transition-transform duration-500 group-hover:scale-x-100"></span>
      </NavLink>
      {item.sub_menu?.length > 0 && <SubMenu subMenu={item.sub_menu} index={index} />}
    </li>
  ));
};

const SubMenu = ({ subMenu, index }) => (
  <ul
    className={`absolute left-0 top-full hidden w-max gap-y-1 rounded-md bg-white shadow-lg hover:flex group-hover:flex ${
      subMenu?.[index]?.is_redirect ? 'flex-col' : 'overflow-auto'
    }`}
  >
    {subMenu.map((subMenuItem) => (
      <li key={subMenuItem.id || subMenuItem.name}>
        <NavLink
          to={getNavigationPath(subMenuItem)}
          className={`flex items-center px-4 py-2 text-gray-800 duration-300 ${
            subMenuItem.is_redirect
              ? 'transition-colors hover:bg-gray-200'
              : 'cursor-default font-bold text-primary'
          }`}
        >
          {subMenuItem.name}
        </NavLink>
        {subMenuItem.sub_menu?.length > 0 && <NestedMenu subMenu={subMenuItem.sub_menu} />}
      </li>
    ))}
  </ul>
);

const NestedMenu = ({ subMenu }) => (
  <ul className="ml-3 w-max">
    {subMenu.map((nestedMenu) => (
      <li key={nestedMenu.id || nestedMenu.name}>
        <NavLink
          to={getNavigationPath(nestedMenu)}
          className="flex items-center px-4 py-2 text-gray-800 transition-colors duration-300 hover:bg-gray-200"
        >
          {nestedMenu.name}
        </NavLink>
      </li>
    ))}
  </ul>
);

export default DesktopNav;

//params: {}
