import React, { Suspense } from 'react';
import { Spinner } from '@material-tailwind/react';
import { Outlet } from 'react-router-dom';
import { Nav, Header, Footer } from '../components';

function MainLayouts() {
  return (
    <>
      <Header />
      <Nav />
      <Suspense fallback={<Spinner className="mx-auto mt-[40vh] h-16 w-16 text-gray-900/50" />}>
        <Outlet />
      </Suspense>
      <Footer />
    </>
  );
}

export default MainLayouts;
