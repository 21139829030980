import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../../apis/axiosBaseQuery';
// import { sanitizeHtml } from '../../../pages/htmlContent/Sanitize';
export const layoutServices = createApi({
  reducerPath: 'layoutRTK',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getNavMenuList: builder.query({
      query: () => ({ url: '/cms/getMenuData', method: 'GET' }),
      transformResponse: (response) => response?.data?.data,
    }),

    getHeaderListData: builder.query({
      query: () => ({ url: '/cms/getHeaderFooterData?type=Header', method: 'GET' }),
      transformResponse: (response) => response?.data?.data,
    }),
    // getHomePageData: builder.query({
    //   query: () => ({ url: '/cms/getSectionContentData?menu_id=1', method: 'GET' }),
    //   transformResponse: (response) => response?.data,
    // }),
    getHomePageData: builder.query({
      query: (menuId) => ({
        url: `/cms/getSectionContentData?menu_id=${menuId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response?.data?.data,

      // query: (id) => ({ url: `/cms/getSectionContentData?menu_id=${id}`, method: 'GET' }),

      // transformResponse: (response) => response?.data?.data,
    }),
    getProductCategories: builder.query({
      // query: (obj) => ({

      //   url: `products?metal_type[0]=${1}?item_master_id=${15}?page=${1}?limit=${12}`,
      //   method: 'GET',
      // }),

      query: (obj) => {
        const params = Object.entries(obj)
          .map(([key, value]) => {
            if (key === 'metal_type') {
              return `metal_type[0]=${value}`;
            }
            return `${key}=${value}`;
          })
          .join('?');

        return {
          url: `/products?${params}?page=${1}?limit=${12}`,
          method: 'GET',
        };
      },

      transformResponse: (response) => response?.data?.data,
    }),

    getProductDetails: builder.query({
      query: (id) => ({
        url: `products/details?product_id=${id}`,
        method: 'GET',
      }),

      transformResponse: (response) => response?.data?.data,
    }),
    getPreviewPage: builder.query({
      query: (id) => ({
        url: `cms/getPreviewData?page_id=${id}`,
        method: 'GET',
      }),

      transformResponse: (response) => response?.data?.data,
    }),
    getStoresData: builder.query({
      query: () => ({ url: 'stores', method: 'GET' }),
      transformResponse: (response) => response?.data,
    }),
    getStoresSearchData: builder.mutation({
      query: (search) => ({
        url: 'stores/search',
        method: 'POST',
        data: search,
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetNavMenuListQuery,
  useGetHeaderListDataQuery,
  useGetHomePageDataQuery,
  useGetProductCategoriesQuery,
  useGetProductDetailsQuery,
  useGetStoresDataQuery,
  useGetStoresSearchDataMutation,
  useGetPreviewPageQuery,
} = layoutServices;
