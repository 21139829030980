import React, { useEffect, useState } from 'react';
import { Spinner } from '@material-tailwind/react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
// // static import
import { REACT_APP_ATTACHMENT_URL } from './config/envConfig';
import MainLayouts from './layouts/MainLayouts';
import { guestRoutes } from './routes';
import { useGetHeaderListDataQuery, useGetNavMenuListQuery } from './store/services/layouts';
import './App.css';

function App() {
  // // Initial state
  // const tokenPresent = localStorage.getItem('jwt_token');

  // redux state and api calling
  const { isLoading } = useGetNavMenuListQuery();
  const { data, isLoading: headerLoading } = useGetHeaderListDataQuery();

  // // local state
  const [appRoutes, setAppRoutes] = useState([]);

  // useEffect hook for set routes on component mount
  useEffect(() => {
    // if (tokenPresent) {
    //   setAppRoutes(userRoutes);
    // } else {
    //   setAppRoutes(guestRoutes);
    // }
    // AOS.init({
    //   offset: 200,
    //   duration: 900,
    //   easing: 'ease-in-sine',
    //   delay: 100,
    // });
    setAppRoutes(guestRoutes);
  }, []);

  const mainContent = appRoutes.map((route) => {
    if (route.component) {
      return (
        <Route
          key={route.name}
          path={`${route.path}`}
          exact={route.exact}
          name={route.name}
          element={<route.component />}
        />
      );
    }

    if (route.redirectRoute) {
      return <Route path="*" key={route.name} element={<Navigate to={`${route.path}`} />} />;
    }

    return null;
  });

  return (
    <>
      {isLoading || headerLoading ? (
        <Spinner className="mx-auto mt-[40vh] h-16 w-16 text-gray-900/50" />
      ) : (
        <>
          <Helmet>
            <link rel="icon" href={`${REACT_APP_ATTACHMENT_URL}${data?.favicon?.value}`} />
            <link
              rel="apple-touch-icon"
              href={`${REACT_APP_ATTACHMENT_URL}${data?.favicon?.value}`}
            />
            <title>Chandukaka Saraf</title>
          </Helmet>
          <Routes>
            <Route element={<MainLayouts />}>{mainContent}</Route>
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
