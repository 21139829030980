export const formatNumToCurrency = (number, locale = 'en-IN', currency = 'INR') => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
  });
  return formatter.format(number);
};

//This is the function which handles broken image and replaces default image, use this in onError eventHandler and pass defaultimage as parameter
export const handleImageLoadingError = (e, defaultImage) => {
  console.error(`Image failed to load or default image displayed : ${e.target.src}`);
  e.target.error = null;
  e.target.src = defaultImage;
};

//This function checks if the value of any key in object is number but given in string than it will convert it to Number dataType to handle truthy and falsy values aprropriatly
export const convertObjectKeysToNumbers = (obj) => {
  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    result[key] = /^\d+$/.test(value) ? Number(value) : value;
  }

  return result;
};

//This function dynamically extract keys from params and create routes to navigate
export const generateNavigationLinkForDynamicPage = (params, path) => {
  if (!params) return `/emptyparams`;
  const objectLength = Object.keys(params).length;
  if (!objectLength) return `/emptyparams`;

  const redirectLink = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  console.log('redirect link ', redirectLink);
  return `${path}/${redirectLink}`;
};

//This function returns route on which it has to redirects
export const getNavigationPath = (item) => {
  if (!item?.is_redirect && !item?.path) {
    console.error(
      `Is_Redirect is flase & Navigation path not provided for item:, PageName: ${item?.name} - PageType: ${
        item?.page_type
      }`
    );
    return '/nullpath&isRedirect';
  }

  if (item?.is_redirect && !item?.path) {
    console.error(
      `Navigation path not provided for item:, PageName: ${item?.name} - PageType: ${
        item?.page_type
      }`
    );

    return '/pathprovidedisnull';
  }
  if (item?.is_redirect && item?.path && !item?.page_type) {
    return `/specifypagetype`;
  }

  if (item?.is_redirect && item?.page_type?.toLowerCase() === 'html') {
    return item?.id ? `/html/${item?.id}` : `/notfound`;
  }

  if (item?.is_redirect && item?.page_type?.toLowerCase() === 'dynamic') {
    return item?.id ? `/dynamic/${item?.id}` : `/notfound`;
  }

  if (item?.is_redirect && item?.path && item?.page_type?.toLowerCase() === 'react') {
    return generateNavigationLinkForDynamicPage(item?.params, item?.path);
  }
};
