import { useMemo } from 'react';
import { convertObjectKeysToNumbers } from '../utils';

const useSliderSetting = (controls) => {
  return useMemo(() => {
    const sliderSettings = convertObjectKeysToNumbers(controls) || {};

    return {
      autoplay: Number(sliderSettings.autoplay) || 0,
      autoplaySpeed: Number(sliderSettings.autoplaySpeed) || 0,
      // centerMode: Boolean(sliderSettings.centerMode),
      centerMode: false,
      dots: Boolean(sliderSettings.dots),
      fade: Boolean(sliderSettings.fade),
      focusOnSelect: Boolean(sliderSettings.focusOnSelect),
      infinite: Boolean(sliderSettings.infinite),
      pauseOnHover: Boolean(sliderSettings.pauseOnHover),
      slidesToScroll: Number(sliderSettings.slidesToScroll) || 1,
      slidesToShow: Number(sliderSettings.slidesToShow) || 1,
      speed: Number(sliderSettings.speed) || 2000,
      swipeToSlide: Boolean(sliderSettings.swipeToSlide),
    };
  }, [controls]);
};

export default useSliderSetting;
