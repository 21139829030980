import React, { useState } from 'react';
import { Drawer, Collapse } from '@material-tailwind/react';
import { FaChevronDown } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { REACT_APP_ATTACHMENT_URL } from '../../config/envConfig';
import { useGetHeaderListDataQuery, useGetNavMenuListQuery } from '../../store/services/layouts';

// MobileMenu Component
function MobileMenu({ open, closeDrawer }) {
  // redux state
  const { data = [] } = useGetNavMenuListQuery();
  const { data: headerData = [] } = useGetHeaderListDataQuery();

  return (
    <Drawer open={open} onClose={closeDrawer} className="p-4">
      <img
        src={`${REACT_APP_ATTACHMENT_URL}${headerData?.logo?.value}`}
        alt="brand logo"
        className="mb-2 w-16"
      />
      <MobileMenuItems menuItems={data} closeDrawer={closeDrawer} />
    </Drawer>
  );
}

const MobileMenuItems = ({ menuItems, closeDrawer }) => {
  const [openMenus, setOpenMenus] = useState({});

  const toggleMenu = (index) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [index]: !prevOpenMenus[index],
    }));
  };

  return (
    <ul className="">
      {menuItems?.map((item, index) => (
        <SubMenuItem
          key={item.id || item.name}
          item={item}
          index={index}
          toggleMenu={toggleMenu}
          isOpen={openMenus[index]}
          closeDrawer={closeDrawer}
        />
      ))}
    </ul>
  );
};

const SubMenuItem = ({ item, index, toggleMenu, isOpen, closeDrawer }) => (
  <>
    <li
      key={item.id || item.name}
      className={`${item.sub_menu?.length > 0 && 'flex cursor-pointer items-center justify-between'}`}
      onClick={item.sub_menu?.length > 0 ? () => toggleMenu(index) : closeDrawer}
    >
      <NavLink to={item.path} className="block py-2 text-gray-800">
        {item.name}
      </NavLink>
      {item.sub_menu?.length > 0 && (
        <FaChevronDown
          className={`transition-transform duration-300 ${isOpen ? 'rotate-180 transform' : ''}`}
        />
      )}
    </li>
    {item.sub_menu?.length > 0 && (
      <Collapse open={isOpen}>
        <NestedMenu menu={item.sub_menu} closeDrawer={closeDrawer} />
      </Collapse>
    )}
  </>
);

const NestedMenu = ({ menu }) => (
  <ul>
    {menu?.map((nestedItem) => (
      <li key={nestedItem.id || nestedItem.name}>
        <NavLink
          to={nestedItem.path}
          className={`block px-4 py-2 text-gray-800 ${nestedItem.sub_menu?.length > 0 ? 'cursor-default pb-0 font-bold' : 'transition-colors duration-300 hover:bg-gray-200'}`}
        >
          {nestedItem.name}
        </NavLink>
        {/* Render nested menus if they exist */}
        {nestedItem.sub_menu?.length > 0 && (
          <Collapse open={true} className="ml-3">
            <NestedMenu menu={nestedItem.sub_menu} />
          </Collapse>
        )}
      </li>
    ))}
  </ul>
);

export default MobileMenu;
