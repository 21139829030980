import React from 'react';
import { IconButton } from '@material-tailwind/react';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';

export const LeftRightArrow = ({ sliderRef }) => {
  const prev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <>
      <div className="slider-container consecutive_slider_container mb-3 me-3 flex justify-end gap-x-3">
        <IconButton className="slider_custom_control_btn" onClick={prev}>
          <GoArrowLeft />
        </IconButton>
        <IconButton className="slider_custom_control_btn" onClick={next}>
          <GoArrowRight />
        </IconButton>
      </div>
    </>
  );
};
