import React from 'react';
import { LeftRightArrow } from '../arrow/LeftRightArrow';
import { Card, Rating } from '@material-tailwind/react';
// import Heading from '../heading/Heading';

const CardTestimonial = ({ data, sliderRef }) => {
  console.log('data testimonial slide', data);
  return (
    <div className="static my-8 flex flex-col items-center space-y-6">
      {/* Image Section */}

      <div className="flex justify-center">
        <img
          src={`${data?.image_url}/public`}
          className="h-[300px] w-[100%] object-cover shadow-lg sm:h-[400px] sm:w-[80%] md:h-[500px] md:w-[735px]"
          alt="Jwellery"
        />
      </div>

      {/* bottom-0 sm:w-[80%] md:absolute xl:right-[8%] 2xl:right-[18%] */}

      <div className="bottom-0 lg:absolute lg:right-[8%] xl:right-[10%] 2xl:right-[18%]">
        <LeftRightArrow sliderRef={sliderRef} />

        <Card
          className="rounded-0 mx-auto mb-4 h-auto w-full bg-white p-6 shadow-2xl drop-shadow-lg lg:mx-0 lg:w-[416px]"
          style={{ border: '3px solid #EFEFEF', borderRadius: '0px' }}
        >
          <p className="mb-4 text-xs leading-relaxed text-black sm:text-sm md:text-base">
            {data?.description ||
              'Highly recommend this brand for anyone in search of beautiful jewellery and excellent customer service'}
          </p>

          {/* Rating Component */}
          <Rating value={4} readonly className="mb-2 w-full sm:w-80" />

          {/* Customer Info */}
          <h1 className="mb-2 text-sm font-bold italic text-primary sm:text-lg">
            Abcd 123 123 123
          </h1>
          <p className="text-xs text-gray-500 sm:text-sm">
            Customer at our Pune-Satara Road Branch
          </p>
        </Card>
      </div>
    </div>
  );
};

export default CardTestimonial;
