import React from 'react';
import { Typography } from '@material-tailwind/react';
import group40 from '../../../assets/default-section-images/Group 40.svg';
import { LeftRightArrow } from '../arrow/LeftRightArrow';
import { RenderIf } from '../../../utils';

const Heading = ({ title, description, sliderRef, arrow, showDescription = true }) => {
  console.log('testimonial description', description);
  return (
    <>
      <h1 className="mb-2 font-primary text-[24px] font-semibold leading-snug tracking-[0.54px] text-[#672a2f] opacity-100 lg:text-[36px] lg:leading-[48px]">
        {title || 'No Title found. Please contact support for more information.'}
        <img src={group40} alt="Group 40" className="ml-2 inline-block h-6 w-6" />
      </h1>
      <div className="mb-2 flex items-center justify-between">
        <div>
          <Typography className={`text-secondary ${!showDescription ? 'hidden' : ''}`}>
            {description || 'No description found. Please contact support for more information.'}
          </Typography>
        </div>
        <RenderIf render={arrow}>
          <LeftRightArrow sliderRef={sliderRef} />
        </RenderIf>
      </div>
    </>
  );
};

export default Heading;
