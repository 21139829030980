import React from 'react';
import Heading from '../../customUi/heading/Heading';
import testimonialBgImage from '../../../assets/images/testimonialbackground.png';
import { Typography } from '@material-tailwind/react';

const NoSectionMatched = ({ title }) => {
  return (
    <div className="section mb-12" style={{ backgroundImage: `url(${testimonialBgImage})` }}>
      <Heading title={`${title} Section Not Found In Client Project`} description="Note:-" />

      <div className="rounded-lg bg-white p-6 pt-0 shadow-md">
        <Typography variant="h6" className="text-gray-700">
          <ul className="space-y-2">
            <li>1. Above section may not be predefined in the client project</li>
            <li>2. Section type name might not match in the CMS system and client project</li>
            <li>3. API response for the section might be missing or incomplete</li>
            <li>4. The section rendering logic could have been incorrectly implemented</li>
            <li>5. Content data array length can be zero</li>
          </ul>
        </Typography>
      </div>
    </div>
  );
};

export default NoSectionMatched;
