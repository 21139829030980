import axiosInstance from './axiosInstance';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method = 'GET', data, params, headers, body }) => {
    try {
      const result = await axiosInstance({
        url: `${baseUrl}${url}`, // Concatenate baseUrl with the endpoint URL
        method,
        data,
        params,
        headers,
        body,
      });
      return { data: result };
    } catch (axiosError) {
      return {
        error: axiosError?.response?.data || axiosError.message,
      };
    }
  };

export default axiosBaseQuery;
