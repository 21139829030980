import React, { useRef } from 'react';
// import { Rating } from '@material-tailwind/react';

import Slider from 'react-slick';
// import { LeftRightArrow } from '../../customUi/arrow/LeftRightArrow';
import testimonialBgImage from '../../../assets/images/testimonialbackground.png';
import Heading from '../../customUi/heading/Heading';
import CardTestimonial from '../../customUi/card/CardTestimonial';
import { RenderIf } from '../../../utils';
import NoSectionMatched from '../others/NoSectionMatched';
import useSliderSetting from '../../../hooks/useSliderSetting';

function TestimonialSlider({ data, title, description, contentData }) {
  const { align_heading } = JSON.parse(data?.controls || {});

  const settings = useSliderSetting(data?.parsedControls?.SimpleSlider);

  // // initial state
  const sliderRef = useRef(null);

  // // local state
  // const settings = {
  //   dots: is_indicator || false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   autoplay: is_autoplay || false,
  // };

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3.5,
  //   slidesToScroll: 4,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: false,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  // // handler

  return (
    <div style={{ backgroundImage: `url(${testimonialBgImage})` }}>
      <RenderIf
        render={data?.content_data?.length > 0}
        elseRender={<NoSectionMatched title={title || 'Testimonial Section'} />}
      >
        <div className={`mx-2 p-0 lg:mx-[120px] text-${align_heading || 'start'}`}>
          <Heading title={title} description={description} />
        </div>

        <div className="slider-container testimonial_slider_container">
          <Slider ref={sliderRef} {...settings}>
            {contentData.map((contentData, index) => (
              <CardTestimonial key={index} data={contentData} sliderRef={sliderRef} />
            ))}
          </Slider>
        </div>
      </RenderIf>
    </div>
  );
}

export default TestimonialSlider;
