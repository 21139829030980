import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
// // static import
import { REACT_APP_ATTACHMENT_URL } from '../../config/envConfig';
import { useGetHeaderListDataQuery } from '../../store/services/layouts';
import DesktopNav from './DesktopNav';
import MobileMenu from './MobileMenu';

function Nav() {
  // redux state
  const { data = [] } = useGetHeaderListDataQuery();

  // // local state
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav className="flex items-center bg-white px-4 py-2 shadow-lg sm:py-0 lg:px-10">
      <FaBars className="cursor-pointer sm:hidden" onClick={toggleMobileMenu} />
      <NavLink to="/" className="mx-auto sm:hidden">
        <img
          src={`${REACT_APP_ATTACHMENT_URL}${data?.logo?.value}`}
          alt="brand logo"
          className="w-16"
        />
      </NavLink>
      <DesktopNav />
      <MobileMenu open={mobileMenuOpen} closeDrawer={toggleMobileMenu} />
    </nav>
  );
}

export default Nav;
