import { lazy } from 'react';

export const guestRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: lazy(() => import('../view/home/Home')),
  },
  {
    path: '/productcategorie',
    name: 'productCategorie',
    exact: true,
    component: lazy(() => import('../view/products/ProductCardTransition')),
  },
  {
    path: '/react/:id',
    name: 'product-catalogues',
    exact: true,
    component: lazy(() => import('../view/products/Product')),
  },
  {
    path: '/previewpage/:id',
    name: 'previewpage',
    exact: true,
    component: lazy(() => import('../view/preview/Preview')),
  },
  {
    path: '/product-catalogues/details/:id',
    name: 'product-details',
    exact: true,
    component: lazy(() => import('../view/products/productDetails/ProductDetails')),
  },
  {
    path: '/dynamic/:id',
    name: 'aboutus',
    exact: true,
    component: lazy(() => import('../view/about-us/AboutUs')),
  },
  {
    path: '/html/:id',
    name: 'page-html',
    exact: true,
    component: lazy(() => import('../../src/pages/htmlContent/MenuData')),
  },

  //---------------------------------  NOT FOUND ROUTES STARTS ------------------------------------------//
  {
    path: '/pathprovidedisnull',
    name: 'NullPathProvided',
    exact: true,
    component: lazy(() => import('../components/customUi/notFound/NotFound')),
  },
  // {
  //   path: '/html/:id',
  //   name: 'menuData',
  //   exact: true,
  //   component: lazy(() => import('../../src/pages/htmlContent/MenuData')),
  // },

  {
    path: '/notfound',
    name: 'NotFound',
    exact: true,
    component: lazy(() => import('../components/customUi/notFound/NotFound')),
  },
  {
    path: '/emptyparams',
    name: 'emptyparams',
    exact: true,
    component: lazy(() => import('../components/customUi/notFound/NotFound')),
  },
  {
    path: '/nullpath&isRedirect',
    name: 'nullpath&isRedirect',
    exact: true,
    component: lazy(() => import('../components/customUi/notFound/NotFound')),
  },
  {
    path: '/specifypagetype',
    name: 'specifypagetype',
    exact: true,
    component: lazy(() => import('../components/customUi/notFound/NotFound')),
  },
  //---------------------------------  NOT FOUND ROUTES ENDS ------------------------------------------//

  // {
  //   redirectRoute: true,
  //   name: 'Home',
  //   path: '/',
  // },
  {
    path: '/find-a-store',
    name: 'findAStore',
    exact: true,
    component: lazy(() => import('../view/FindStore/FindStore')),
  },
];

export const userRoutes = [{}];
