import axios from 'axios';
import { REACT_APP_API_URL } from '../config/envConfig';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Guest-Session-ID': '658d44d912da1',
  },
  // withCredentials: true,
});

// Add a request interceptor to include the auth token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken'); // Retrieve token from storage or other sources
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor for error handling
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 401:
          console.warn('Unauthorized - Redirecting to login');
          break;
        case 403:
          console.warn('Forbidden - Access denied');
          break;
        case 500:
          console.warn('Server error - Try again later');
          break;
        default:
          console.warn(`Unexpected error - Status code: ${status}`);
      }
    } else if (error.request) {
      console.warn('Network error - Please check your connection');
    } else {
      console.warn('Error setting up the request:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
